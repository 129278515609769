    const sitemapdata = [
        {
            en_title: 'Home',
            kn_title: 'ಮನೆ',
            link: '/'
        },
        {
            en_title: 'Food Order',
            kn_title: 'ಅನ್ನದ ಆದೇಶ',
            link: '/food'
        },
        {
            en_title: 'Events',
            kn_title: 'ಕಾರ್ಯಕ್ರಮಗಳು',
            link: '/events'
        },
        {
            en_title: 'Room Booking',
            kn_title: 'ಕೊಠಡಿ ಬುಕ್ಕಿಂಗ್',
            link: '/room'
        },
        {
            en_title: 'Gallery',
            kn_title: 'ಗ್ಯಾಲರಿ',
            link: '/gallery'
        },
        {
            en_title: 'Contact Us',
            kn_title: 'ನಮ್ಮನ್ನು ಸಂಪರ್ಕಿಸಿ',
            link: '/contact'
        },
        {
            en_title: 'About Us',
            kn_title: 'ನಮ್ಮ ಬಗ್ಗೆ',
            link: '/about'
        },
        {
            en_title: "FAQ's",
            kn_title: 'ಪದೇ ಪದೇ ಕೇಳಲಾಗುವ ಪ್ರಶ್ನೆಗಳು',
            link: '/faqs'
        },
        {
            en_title: 'Privacy Policy',
            kn_title: 'ಗೌಪ್ಯತಾ ನೀತಿ',
            link: '/privacy'
        },
        {
            en_title: 'Terms and Conditions',
            kn_title: 'ನಿಯಮಗಳು ಮತ್ತು ಶರತ್ತುಗಳು',
            link: '/terms_and_conditions'
        },
        {
            en_title: 'Help',
            kn_title: 'ಸಹಾಯ',
            link: '/help'
        }
    ];

    export default sitemapdata;
